import React, { useState } from 'react';
import './App.css';
import { Lander } from './components/Lander';
import { Prompting } from './components/Prompting';

function App() {
  const [currentView, setCurrentView] = useState('lander');
  
  const getContent = () => {
    if (currentView === 'lander') {
      return <Lander onAloitaClick={() => setCurrentView('prompting')} />;
    }
    if (currentView === 'prompting') {
      return <Prompting />;
    }
    return null;
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="main-content">
          {getContent()}
        </div>
        <div id="feedback" className="bottom">
          <p>joulukortti.net@gmail.com</p>
        </div>
        </header>
    </div>
    
  );
}

export default App;
