import React from 'react';
import styles from './Lander.module.css';

// create props with onAloitaClick callback
interface LanderProps {
  onAloitaClick: () => void;
}

export const Lander = (props: LanderProps) => {
  return <>
    <img src="./uusivuosi-icon.png" alt="logo" className={styles.logo} />
    <span className={styles.landerText}>Tervetuloa askartelemaan<br />uuden vuoden digi-kortti!</span>
    <span className={styles.landerButton} onClick={() => props.onAloitaClick()}>Aloita</span>
  </>
};