const apiUrl = 'https://r3ru3mpyg4.execute-api.eu-west-1.amazonaws.com/default';

export async function fetchFromAPI(requestPath: string, data: any = {}): Promise<any> {
  const url = `${apiUrl}/${requestPath}`; // Join apiUrl with requestPath
  try {
    console.log('calling url:', url);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...data
      }),
    });
    console.log('got response:', response)
    if (!response.ok) {
      const text = await response.text();
      console.log(text);
      try {
        const errorBody = JSON.parse(text); // Parse the text as JSON
        throw new Error(errorBody.message || `HTTP error! status: ${response.status}`);
      } catch (jsonError) {
        // Handle case where text is not JSON
        throw new Error(text);
      }
    }

    return response.json();
  } catch (error) {
    console.log('entry');
    console.error('Error:', error);
    throw error;
  }
}

export const saveStatsEvent = async (eventName: string, sessionId: string, imageGuid?: string, imageUrl?: string): Promise<void> => {
  try {
    const statsEvent: { event_name: string; session_id: string; image_guid?: string, image_url?: string } = {
      event_name: eventName,
      session_id: sessionId
    };

    if (imageGuid) {
      statsEvent.image_guid = imageGuid;
    }

    if (imageUrl) {
      statsEvent.image_url = imageUrl;
    }

    await fetchFromAPI('stats', statsEvent);
    console.log('Stats event sent successfully:', eventName);
  } catch (error) {
    console.error('Error sending stats event:', error);
  }
};