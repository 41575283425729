import React, { useEffect, useState } from 'react';
import styles from './Prompting.module.css';
import { fetchFromAPI, saveStatsEvent } from '../utils/api';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { v4 as uuidv4 } from 'uuid';
import { saveAs } from 'file-saver';
import { resizeImage } from '../utils/resize';

export const Prompting: React.FC = () => {
  const [sessionID, setSessionID] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedStyling, setSelectedStyling] = useState<string>('cartoon');
  const [selectedPrompt, setSelectedPrompt] = useState<string | null>(null);
  const [guid, setGuid] = useState<string | null>(null);
  const [currentState, setCurrentState] = useState<string>('default');
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const [savingImageUrl, setSavingImageUrl] = useState<string | null>(null);

  const location = window.location;
  const queryParams = new URLSearchParams(location.search);
  const devParam = queryParams.get('dev');

  useEffect(() => {
    // Check if a sessionID already exists in localStorage
    const savedSessionID = localStorage.getItem('joulukortti-sessionID');

    if (savedSessionID) {
      // Use the existing sessionID
      setSessionID(savedSessionID);
    } else {
      // Generate a new UUID for the session and save it in localStorage
      const newSessionID = uuidv4();
      localStorage.setItem('joulukortti-sessionID', newSessionID);
      saveStatsEvent('session_start', newSessionID);
      setSessionID(newSessionID);
    }
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const checkPostcardStatus = async () => {
      try {
        const response = await fetchFromAPI('get-postcard-status', { guid });
        console.log(response);
        if (response.progress >= 100) {
          const renderedImageUrls = await Promise.all(response.imageUrls.map(async (url: string) => {
            const response = await fetch(url);
            const imageBlob = await response.blob();
            const dataURL = await renderImage(imageBlob);
            const data = atob(dataURL.split(',')[1]);
            const array = [];
            for (let i = 0; i < data.length; i++) {
              array.push(data.charCodeAt(i));
            }
            const newBlob = new Blob([new Uint8Array(array)], { type: 'image/png' });
            return URL.createObjectURL(newBlob);
          }));

          setImageUrls(renderedImageUrls);
          // iterate images and save stats
          renderedImageUrls.forEach((url: string) => {
            saveStatsEvent('image_generated_' + selectedStyling, sessionID || 'undefined', guid || '', url);
          });
          setCurrentState('done'); // Stop polling when progress is complete
        } else if (response.progress > 0) {
          setProgress(response.progress);
        }
      } catch (error) {
        // noop
      }
    };

    if (currentState === 'polling' && guid) {
      intervalId = setInterval(() => {
        checkPostcardStatus();
      }, 5000);
    }

    // Cleanup on unmount or when polling stops
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [currentState, guid]); // Dependencies for the useEffect

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setSelectedImage(file);
      setCurrentState('styling');
    }
  };

  const onStylingClick = async (prompt: string) => {
    setSelectedStyling(prompt);
    uploadImage(prompt);
  }

  const handleSelectedPromptChange = async (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSelectedPrompt(event.target.value);
  }

  const uploadImage = async (prompt: string) => {
    if (!selectedImage) return;
    try {
      setProgress(0);
      // Resizing the image
      setCurrentState('uploading');
      const resizedImageDataURL = await resizeImage(selectedImage, 1024, 1024);

      const response = await fetchFromAPI('upload-image', {
        file: resizedImageDataURL,
        file_extension: 'png', // Output is always PNG
        string: prompt || 'No custom prompt',
      });

      setCurrentState('polling');
      console.log('Response (upload):', response);
      try {
        const responseVariations = await fetchFromAPI('create-postcard-variations', {
          guid: response.guid,
        });
        saveStatsEvent('image_upload', sessionID || 'undefined', guid || '');
        setGuid(response.guid);
      } catch (error) {
        console.error('Error creating postcard variations:', error);
      }
      // Handle the response here
    } catch (error) {
      setCurrentState('error');
      console.error('Upload error:', error);
    }
  }

  const triggerFileInput = () => {
    const fileInput = document.getElementById('imageInput') as HTMLInputElement;
    fileInput.click();
  };

  function isIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  }

  const renderImage = async (blob: Blob): Promise<string> => {
    const image = new Image();
    image.src = URL.createObjectURL(blob);
  
    await document.fonts.load("96px 'Dancing Script'");
    await document.fonts.load("24px Futura");

    return new Promise((resolve, reject) => {  
      image.onload = () => {
        // Create canvas and draw the image onto it
        const canvas = document.createElement('canvas');
        const canvasWidth = 682; // for example, 800 pixels
        const canvasHeight = 1024; // for example, 600 pixels
  
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Error in creating canvas context');
          return;
        }
        // Calculate scaling to maintain aspect ratio
        const scale = Math.min(canvasWidth / image.width, canvasHeight / image.height);
        const x = (canvasWidth / 2) - (image.width / 2) * scale;
        const y = (canvasHeight / 2) - (image.height / 2) * scale;
        ctx.drawImage(image, x, y, image.width * scale, image.height * scale);
  
        // Add caption
        const captionText = "Onnellista uutta vuotta!";
        ctx.font = "bold 64px 'Dancing Script'"; // Customize the font size and type
        ctx.fillStyle = "rgb(224, 224, 224)"; // Customize the text color
        ctx.textAlign = "center";
        // Set shadow properties
        ctx.shadowColor = 'rgba(0, 0, 0, 0.7)'; // Shadow color
        ctx.shadowBlur = 8; // Blur level
        ctx.shadowOffsetX = 3; // Horizontal offset
        ctx.shadowOffsetY = 3; // Vertical offset
        ctx.fillText(captionText, canvas.width / 2, canvas.height - 160); // Adjust position as needed

        const captionText2 = "2024";
        ctx.font = "bold 76px 'Dancing Script'"; // Customize the font size and type
        ctx.fillStyle = "rgb(224, 224, 224)"; // Customize the text color
        ctx.textAlign = "center";
        // Set shadow properties
        ctx.shadowColor = 'rgba(0, 0, 0, 0.7)'; // Shadow color
        ctx.shadowBlur = 8; // Blur level
        ctx.shadowOffsetX = 3; // Horizontal offset
        ctx.shadowOffsetY = 3; // Vertical offset
        ctx.fillText(captionText2, canvas.width / 2, canvas.height - 70); // Adjust position as needed

        // Reset shadow for smaller text
        ctx.shadowColor = 'rgba(0, 0, 0, 1)'; // Shadow color
        ctx.textAlign = "center";
        ctx.shadowBlur = 2;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.textAlign = "right";
  
        // Additional smaller text
        ctx.font = "24px Futura"; // Smaller, normal font
        ctx.fillStyle = "rgb(255, 255, 255, 0.5)"; // Different text color
  
        ctx.strokeStyle = "rgb(64, 64, 64, 0.5)"; // Stroke color
        ctx.lineWidth = 3; // Stroke width
  
        // Draw text stroke
        ctx.strokeText("joulukortti.net", canvas.width - 10, 28); // Position at the bottom
  
        ctx.fillText("joulukortti.net", canvas.width - 10, 28); // Position at the bottom
        // Set up stroke properties
  
        // Convert canvas to data URL
        const dataURL = canvas.toDataURL('image/png');
  
        // Cleanup
        URL.revokeObjectURL(image.src);
  
        resolve(dataURL);
      };
  
      image.onerror = () => {
        reject('Error in loading image');
      };
    });
  };
  
  const downloadImage = async (url: string): Promise<void> => {
    //if (isIOS()) {
    if (isIOS()) {
      // Open the image in a new tab for iOS devices
      window.open(url, '_blank');
    } else {
      // Download the image for other devices
      setSavingImageUrl(url);
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`Error: ${response.statusText}`);

        const imageBlob = await response.blob();

        // Get the filename
        const filename = url.split('/').pop();
        const finalFilename = filename ? 'joulukortti_' + filename : 'joulukortti.png';

        // Use FileSaver to save the file
        saveAs(imageBlob, finalFilename);

        setSavingImageUrl(null);
      } catch (error) {
        console.error('Download error:', error);
        setSavingImageUrl(null);
      }
    }
  };
  
  const reuploadImage = async (prompt: string) => {
    if (selectedImage) {
      setProgress(0);

      try {
        const resizedImageDataURL = await resizeImage(selectedImage, 1024, 1024);

        setCurrentState('uploading');
        const response = await fetchFromAPI('upload-image', {
          file: resizedImageDataURL,
          file_extension: 'png', // Output is always PNG
          string: prompt || 'No custom prompt',
        });

        setCurrentState('polling');
        console.log('Response (re-upload):', response);
        try {
          const responseVariations = await fetchFromAPI('create-postcard-variations', {
            guid: response.guid,
          });
          saveStatsEvent('image_reupload', sessionID || 'undefined', response.guid || '');
          console.log('Response (variations):', responseVariations);
          setGuid(response.guid);
        } catch (error) {
          console.error('Error creating postcard variations:', error);
        }
      } catch (error) {
        setCurrentState('error');
        console.error('Re-upload error:', error);
      }
    };

  };


  return (
    <>
      {currentState !== 'done' && <img src="./uusivuosi-icon.png" alt="logo" className={styles.logo} />}
      {currentState === 'error' && <p className={styles.errorText}>Jotain meni pieleen! Yritä uudelleen.</p>}
      {currentState === 'default' && <>
        <span className={styles.instructionText}>Miltä sinä tai lemmikkisi näytätte?</span>
        <span className={styles.generateButton} onClick={triggerFileInput}>Valitse kuva</span>
        <input
          type="file"
          id="imageInput"
          style={{ display: 'none' }}
          onChange={handleImageChange}
          accept="image/jpeg,image/png"
        />
      </>}
      {currentState === 'styling' && devParam === null && <>
        <span className={styles.instructionText}>Minkä tyylisen kortin haluat?</span>
        <div className={styles.bottomButtons}>
          <span className={styles.stylingButton} onClick={() => onStylingClick('1920')}>🍾 1920</span>
          <span className={styles.stylingButton} onClick={() => onStylingClick('1970')}>💃1970🕺</span>
          <span className={styles.stylingButton} onClick={() => onStylingClick('2020')}>🥂 2020</span>
        </div>
      </>}
      {currentState === 'styling' && devParam !== null && <>
        <span className={styles.instructionText}>Minkä tyylisen kortin haluat?</span>
        <textarea
          style={{ width: '40%', height: '100px' }}
          onChange={(e) => handleSelectedPromptChange(e)}>
        </textarea>
        <span className={styles.generateButton} onClick={() => onStylingClick(selectedPrompt || '')}>Save</span>
      </>}
      {currentState === 'uploading' && <div><div className={styles.spinner}></div>Lähetetään kuvaa...</div>}
      {currentState === 'polling' && progress === 0 && <div><div className={styles.spinner}></div>Raketteja viritellään...</div>}
      {currentState === 'polling' && progress > 0 && <div><div className={styles.spinner}></div>Korttisi on valmistumassa...</div>}
      {currentState === 'polling' && progress > 0 && (
        <div className={styles.progressBar}>
          <div className={styles.progressBarFill} style={{ width: `${progress}%` }}>{progress} %</div>
        </div>
      )}
      {currentState === 'done' && imageUrls.length > 0 && (
        <div className={styles.imagesContainer}>
          {imageUrls.map((url, index) => (
            <div key={index} className={styles.imageWrapper}>
              <img src={url} alt={`Generated Image ${index + 1}`} className={styles.generatedImage} />
              <button className={styles.saveButton} onClick={() => { downloadImage(url) }}>
                {savingImageUrl === url ? <div className={styles.smallSpinner}></div> : <i className="bi bi-download" style={{ marginRight: '10px' }}></i>}
                Tallenna
              </button>
            </div>
          ))}
        </div>
      )}
      {currentState === 'done' && (<>
        <div className={styles.bottomButtons}>
          <button className={styles.generateAgainButton} onClick={() => reuploadImage(selectedStyling)}>
            <i className="bi bi-arrow-repeat" style={{ paddingRight: '4px' }} /> Luo uudestaan
          </button>
          <button className={styles.generateAgainButton} onClick={triggerFileInput}>
            <i className="bi bi-upload" style={{ paddingRight: '6px' }} /> Lähetä uusi kuva
            <input
              type="file"
              id="imageInput"
              style={{ display: 'none' }}
              onChange={handleImageChange}
              accept="image/jpeg,image/png"
            />
          </button>
        </div>
      </>
      )}
    </>
  );
};
